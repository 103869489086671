// modena template

$brand-primary: #ce2b37; // rosso scarlatto
$brand-secondary: #009246; // verde felce

$primary: $brand-primary;
$secondary: $brand-secondary;

$headings-color: darken($brand-secondary, 10%);

$navbar-light-color: #fff;
$navbar-light-hover-color:          #fff;
$navbar-light-active-color:         #fff;
$navbar-light-disabled-color:       #ddd;
$navbar-light-toggler-border:       #fff;

$navbar-default-bg: $secondary;

@import "base";

#topnav {
	background-color: $secondary;
}

#jumbotron {
	@include gradient-radial($body-bg, lighten($brand-secondary, 50%));

	h1, h2, h3, h4 {
		color: darken($primary, 10%);
	}

	&[data-background] {
		color: $white;
		background: $gray-800 none;

		p, h1, h2, h3, h4 {
			text-shadow: 1px 1px 4px $black;
		}

		a:not([class]) {
			color: $secondary;
		}

		h1, h2, h3, h4 {
			color: $white;
		}

		.input-group {
			border-radius: $border-radius-lg;
			box-shadow: 1px 1px 4px $black;
		}
	}
}

footer {
	background-color: lighten($brand-secondary, 50%);
	color: darken($brand-primary, 15%);
}

@include color-mode(dark) {
	#jumbotron {
		@include gradient-radial($body-bg-dark, $body-secondary-bg-dark);
	}

	footer {
		background-color: $body-secondary-bg-dark;
		color: $gray-500;

		a, .nav-link {
			color: $gray-200;

			&:hover,
			&:focus {
				color: $white;
			}

		}
	}
}
